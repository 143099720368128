.home-one {
    /* border-top: 4px solid white; */
    height: 70vh;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.home-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    /* border-top: 4px solid white; */
}

.home-header h1 {
    color: white;
    font-family: 'Allison', cursive;
    font-size: 50px;
    letter-spacing: 5px;

}

.home-full-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    background-color: #D1C0A870;
    margin-bottom: 40px;
}

.home-full-image img {
    /* object-fit: ; */
    /* height: 100vh; */
    width: 100%;
    opacity: 0.9;
    transition: 200ms ease-in;
}

.home-full-image img:hover {
    transform: scale(1.05);
}

.home-one-left {
    flex: 0.4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 2px solid white; */

}

.home-one-right {
    flex: 0.6 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px;
}

.home-one-right h2 {
    font-size: 50px;
    color: black;
}

.home-one-right p {
    font-size: 20px;
    color: black;
    padding: 20px;
}

.home-one-left-image-container {
    /* margin-top: 100px; */
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 100px; */
    overflow: hidden;
    height: 70vh;
    /* padding: 100px; */
}

.home-one-image {
    /* height: 70vh; */
    /* width: 50vw !important; */
    /* height: 75%; */
    width: 80%;
    object-fit: contain;
    transition: 500ms ease-in;

}

.home-one-image:hover {
    transform: scale(1.05);
}

.home-two {
    border-top: 2px solid white;
    height: 70vh;
    width: 100vw;
    background-color: #000000E0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* width: 100%;  */
}

.home-two-left {
    height: 70vh;
    flex: 0.6;
    border-right: 2px solid white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 40px; */
}

.home-two-left h2 {
    color: white;
    font-size: 40px;
    padding: 20px;
}

.home-two-left p {
    color: white;
    font-size: 20px;
    text-align: center;
    padding: 40px;

}

.home-two-left img {
    object-fit: contain;
    height: 50%;
    width: auto;
}

.home-two-right {
    height: 70vh;
    flex: 0.4;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-two-right iframe {
    /* border: 2px solid white; */
    /* border-radius: 5px; */
    height: 100%;
    width: 100%;
}

.image-gallery-image {
    height: 70vh !important;
    object-fit: cover !important;
    /* width: 100%; */
    /* width: 100vw !important; */
    /* max-height: 400px !important; */
}

.image-gallery {
    width: 100vw !important
}

.home-three {
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    height: 70vh;
    background-color: #000000F2;
}

.home-room-boxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: #D1C0A870;
}

.room-box-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.room-box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.room-box-title h2 {
    margin-top: 50px;
    font-size: 50px;  
    padding: 20px;
 
}

.room-box-title p {
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    padding: 20px 100px 20px 100px;

}

.room-box-header h2 {
    margin-top: 50px;
    font-size: 50px;
}

.room-box-header p {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 20px;
    text-align: center;
    padding: 20px 100px 20px 100px;
}

.room-box-container {
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
}


/* Screen changes */
@media only screen and (max-width: 991px) {
    .home-one {
        flex-direction: column !important;
        height: max-content;
    }

    .home-one-left {
        border: none;
    }

    .home-one-left-image-container {
        width: 100%;
    }

    .home-one-image {
        width: 100vw !important;
    }

    .home-two {
        flex-direction: column !important;
        height: max-content !important;
    }

    .home-two-right iframe {
        height: 50vh !important;
    }
}

@media screen and (max-width: 480px) {
    .home-full-image {
        height: 60vh !important;
    }

    .home-full-image img {
        height: 60vh !important;
        object-fit: cover;
        width: auto;

    }
}

@media screen and (max-width: 768px) {
    .home-full-image {
        height: 80vh;
    }

    .home-full-image img {
        height: 80vh;
        object-fit: cover;
        width: auto;

    }

    .room-box-header p {
        padding: 20px;
    }

    .room-box-title h2 {
        text-align: center;
    }

    .home-one-right h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .home-one-left-image-container {
        height: auto;
    }

    .room-box-container {
        flex-direction: column !important;
    }

    .home-three .image-gallery {
        width: 100% !important;
    }
}

