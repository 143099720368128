/* The Modal (background) */
.image-modal {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
    transition: ease-in 3ms;
  }
  
  /* Modal Content/Box */
  .image-modal-content {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* margin: 15% auto; */
    /* padding: 20px; */
    /* border: 1px solid #888; */
    /* width: 40%;  */
    height: 100vh;
    width: 100%;
  }

  .image-modal-content img {
      /* opacity: 0.1; */
      width: 95vw !important;
  }