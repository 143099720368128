.nearby-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    /* width: 30vw; */
    background-color: white;
    margin-bottom: 40px;
    box-shadow: 5px 5px 5px grey;
    transition: 200ms ease-in;
    flex: 0.4;
}

.nearby-box:hover {
    transform: scale(1.05);
} 

.nearby-box-image-container {
    /* min-height: 60%; */
    /* height: 60%; */
    flex: 0.6;
    overflow: hidden;
    width: 100%;
}

.nearby-box-image-container img {
    height: 180px;
    width: 100%;
    transition: 200ms ease-in;
    object-fit: cover;
}

.nearby-box-image-container img:hover {
    transform: scale(1.05);
}

.nearby-box-content {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nearby-box h3 {
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
}

.nearby-box p {
    text-align: center;
}

.nearby-box-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.nearby-box-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 25px 10px 25px;
    border: 1px solid #D1C0A870;
    background-color: transparent;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 14px;
}

.nearby-box-button-row button:hover {
    cursor: pointer;
    background-color: #D1C0A870;
    color: black;
}

@media screen and (max-width: 480px) {

    .nearby-box {
        width: 80vw;
        flex: 1;
    }

}

@media screen and (max-width: 768px) {
    .nearby-box {
        /* width: 70vw !important; */
        /* flex: 0.5 !important; */
        /* height: 50vh; */

    }

    .nearby-box h3 {
        font-size: 85%;
    }

    .nearby-box p {
        font-size: 95%;
    }

    .nearby-box-image-container img {
    }
}
