@import url(https://fonts.googleapis.com/css2?family=Allison&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Della+Respira&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  outline-width: 0;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 600ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 6000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.header-img {
  height: 40vh;
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .header-img {
    height: 25vh;
  }
}

.header {
    /* background-color: #040403; */
    height: 100px;
    color: black;
    background-color: white;
    display: flex;
    align-items: center;
    z-index: 800;
    position: sticky !important;
    /* width: 100vw; */
    top: 0;
    width: 100vw;
}
 
.header h1 {
    font-size: 40px;
    font-family: 'Allison', cursive;
    /* background-color: black; */
    color: black;
    outline-width: 0;
}

.nav-header {
    text-decoration: none;

}

.header-left img {
    transition: 200ms ease-in;
}

.header-left img:hover {
    cursor: pointer;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

.header-navlink {
    font-size: 20px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    transition: ease-in-out 0.4s;
    /* font-family: 'Della Respira', serif;} */
    font-family: 'Gothic A1', sans-serif;
    /* font-weight: 200; */
    
}

.header-navlink:hover {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    /* color: #ffffff; */
    color: #D1C0A8;

}



.header-navlink-active {
    color: #D1C0A8;
}

.header-navlink-active:hover {
    -webkit-transform: none;
            transform: none;
    /* color: white; */
    cursor: default;
}


.header-left {
    flex: 0.4 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-middle {
    flex: 0.5 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-right {
    flex: 0.1 1;
    align-items: center;
}

.MuiSvgIcon-root:hover {
    cursor: pointer;
}

/* .MuiSvgIcon-root {
    display: none;
} */

.header-right {
    display: none;
}

@media only screen and (max-width: 481px) {
    .header-left img {
        max-width: 90vw;
    }

    .header-left {
        /* display: none; */
        max-width: 90vw;

    }

}

@media only screen and (max-width: 991px) {
    /* .MuiSvgIcon-root {
        display: block;
    } */

    .header-left {
        flex: 0.9 1 !important;
    }

    .header-right {
        flex: 0.1 1 !important;
    }

    .header-right {
        display: block;
        justify-content: flex-end !important;
    }

    .header-middle {
        display: none;
    }
}


@media only screen and (max-width: 991px) {
    .header h1 {
        font-size: 30px;
    }

    .header-navlink {
        font-size: 18px;
    }

    
}
.dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    background-color: white;
    padding-right: 20px;
    /* width: max-content; */
    padding-bottom: 20px;
    z-index: 700;
    right: 0 !important;
    position: fixed;
    width: 100%;
    height: 0px;
    transition: height 1s;
}

.dropdown-navlink {
    margin-bottom: 10px !important;
}

.footer {
    background-color: white;
    height: 20vh;
    color: black;
    display: flex;
    /* position: fixed; */
    bottom: 0;
    z-index: 800;
    width: 100%;
}

.footer-left {
    flex: 0.3 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.footer h1 {
    font-family: 'Allison', cursive;
}

.footer-middle {
    flex: 0.4 1;
}

.footer-right {
    flex: 0.3 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-right > h2 {
    margin-bottom: 7px;
    font-size: 24px;
}

.footer-right p {
    margin-bottom: 4px;
    font-size: 17px;
}

@media only screen and (max-width: 480px) {
    .footer {
        flex-direction: column;
        height: -webkit-max-content;
        height: max-content;
        /* padding: 10px; */
    }

    .footer-middle {
        display: none;
    }

    .footer-right {
        display: none;
    }

    .footer-left img {
        width: 100% ;
    }
}

@media only screen and (max-width: 768px) {
    .footer-left {
        flex: 1 1;
        max-width: 100%;
    }
    .footer-right {
        display: none;
    }
    .footer-middle {
        display: none;
    }
}
.home-one {
    /* border-top: 4px solid white; */
    height: 70vh;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.home-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    /* border-top: 4px solid white; */
}

.home-header h1 {
    color: white;
    font-family: 'Allison', cursive;
    font-size: 50px;
    letter-spacing: 5px;

}

.home-full-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    background-color: #D1C0A870;
    margin-bottom: 40px;
}

.home-full-image img {
    /* object-fit: ; */
    /* height: 100vh; */
    width: 100%;
    opacity: 0.9;
    transition: 200ms ease-in;
}

.home-full-image img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.home-one-left {
    flex: 0.4 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 2px solid white; */

}

.home-one-right {
    flex: 0.6 1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px;
}

.home-one-right h2 {
    font-size: 50px;
    color: black;
}

.home-one-right p {
    font-size: 20px;
    color: black;
    padding: 20px;
}

.home-one-left-image-container {
    /* margin-top: 100px; */
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 100px; */
    overflow: hidden;
    height: 70vh;
    /* padding: 100px; */
}

.home-one-image {
    /* height: 70vh; */
    /* width: 50vw !important; */
    /* height: 75%; */
    width: 80%;
    object-fit: contain;
    transition: 500ms ease-in;

}

.home-one-image:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.home-two {
    border-top: 2px solid white;
    height: 70vh;
    width: 100vw;
    background-color: #000000E0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* width: 100%;  */
}

.home-two-left {
    height: 70vh;
    flex: 0.6 1;
    border-right: 2px solid white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 40px; */
}

.home-two-left h2 {
    color: white;
    font-size: 40px;
    padding: 20px;
}

.home-two-left p {
    color: white;
    font-size: 20px;
    text-align: center;
    padding: 40px;

}

.home-two-left img {
    object-fit: contain;
    height: 50%;
    width: auto;
}

.home-two-right {
    height: 70vh;
    flex: 0.4 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-two-right iframe {
    /* border: 2px solid white; */
    /* border-radius: 5px; */
    height: 100%;
    width: 100%;
}

.image-gallery-image {
    height: 70vh !important;
    object-fit: cover !important;
    /* width: 100%; */
    /* width: 100vw !important; */
    /* max-height: 400px !important; */
}

.image-gallery {
    width: 100vw !important
}

.home-three {
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    height: 70vh;
    background-color: #000000F2;
}

.home-room-boxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: #D1C0A870;
}

.room-box-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.room-box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.room-box-title h2 {
    margin-top: 50px;
    font-size: 50px;  
    padding: 20px;
 
}

.room-box-title p {
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    padding: 20px 100px 20px 100px;

}

.room-box-header h2 {
    margin-top: 50px;
    font-size: 50px;
}

.room-box-header p {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 20px;
    text-align: center;
    padding: 20px 100px 20px 100px;
}

.room-box-container {
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
}


/* Screen changes */
@media only screen and (max-width: 991px) {
    .home-one {
        flex-direction: column !important;
        height: -webkit-max-content;
        height: max-content;
    }

    .home-one-left {
        border: none;
    }

    .home-one-left-image-container {
        width: 100%;
    }

    .home-one-image {
        width: 100vw !important;
    }

    .home-two {
        flex-direction: column !important;
        height: -webkit-max-content !important;
        height: max-content !important;
    }

    .home-two-right iframe {
        height: 50vh !important;
    }
}

@media screen and (max-width: 480px) {
    .home-full-image {
        height: 60vh !important;
    }

    .home-full-image img {
        height: 60vh !important;
        object-fit: cover;
        width: auto;

    }
}

@media screen and (max-width: 768px) {
    .home-full-image {
        height: 80vh;
    }

    .home-full-image img {
        height: 80vh;
        object-fit: cover;
        width: auto;

    }

    .room-box-header p {
        padding: 20px;
    }

    .room-box-title h2 {
        text-align: center;
    }

    .home-one-right h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .home-one-left-image-container {
        height: auto;
    }

    .room-box-container {
        flex-direction: column !important;
    }

    .home-three .image-gallery {
        width: 100% !important;
    }
}


/* Slideshow container */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }
  
  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .active {
    background-color: #717171;
  }
  
  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @-webkit-keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  /* On smaller screens, decrease text size */
  @media only screen and (max-width: 300px) {
    .text {font-size: 11px}
  } 

.carousel-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.room-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-max-content;
    height: max-content;
    width: 30vw;
    background-color: white;
    margin-bottom: 100px;
    box-shadow: 5px 5px 5px grey;
    transition: 200ms ease-in;
}

.room-box:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.room-box-image-container {
    flex: 0.6 1;
    overflow: hidden;
    width: 100%;
}

.room-box-image-container img {
    height: auto;
    width: 100%;
    transition: 200ms ease-in;
}

.room-box-image-container img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.room-box-content {
    flex: 0.4 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.room-box h3 {
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
}

.room-box-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-box-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 25px 10px 25px;
    border: 1px solid #D1C0A870;
    background-color: transparent;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 14px;
    margin-bottom: 20px;
}

.room-box-button-row button:hover {
    cursor: pointer;
    background-color: #D1C0A870;
    color: black;
}

@media screen and (max-width: 480px) {

    .room-box {
        width: 80vw !important;
    }


}

@media screen and (max-width: 768px) {
    .room-box {
        width: 70vw !important;
    }
    .room-box-button-row button {
        margin-bottom: 20px;
    }

}

.rooms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
    background-color: #D1C0A870;
    padding-bottom: 100px;
    padding-top: 100px;
    max-width: 100vw;
}

.rooms p {
    font-size: 100% !important;
}

.rooms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
    /* padding-bottom: 100px; */
}

.rooms-header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    width: 100%;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    margin-bottom: 40px;
}

.rooms-header-row > h1 {
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    color: white;
    /* margin-bottom: 40px; */
}
.main-room {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    width: 90%;
    color: black;
    background-color: white;
    /* margin-top: 100px; */
    border: 2px solid white;
}

.main-room-left {
    flex: 0.5 1;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-room-right {
    flex: 0.5 1;
    flex-direction: column;

}

.main-room-right h1 {
    margin-bottom: 20px;
}

.main-room-right > p {
    font-size: 120%;
}

.main-room-image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100vw; */
}

.main-room-image-container image {
    height: 30vh;
    width: auto;
}

.main-room-image {
    height: 50vh;
}

.icon-group {
    display: flex;
    align-items: center;
    font-size: 120%;
    margin-bottom: 5px;
}

.icon-group p {
    margin-left: 30px;
}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 991px) {
    .main-room {
        height: 80vh !important;
    }

}

@media only screen and (max-width: 992px) {
    .main-room {
        flex-direction: column !important;
        height: -webkit-max-content !important;
        height: max-content !important;
        padding: 20px;
        width: 90vw;
    }

    .main-room-image-container {
        width: 90vw;
    }

    .main-room-right {
        padding: 20px;
    }

    .main-room-right p {
        font-size: 100%;
        margin-top: 10px;
    }

    .main-room-right h1 {
        margin-top: 10px;
    }

}
.room-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.room-gallery {
    width: 40vw;
    /* height: 50vh !important  ; */
}



.room-gallery .image-gallery-image {
    height: 50vh !important;
    width: 40vw;
    object-fit: fill;
}

.fullscreen .image-gallery-image {
    height: 100vh !important;
    width: 100%;
    object-fit: contain !important;

}

.fullscreen {
    height: 100vh !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid black !important;
}

.image-gallery-thumbnail:hover {
    border: 4px solid black !important;
}


@media only screen and (max-width: 991px) {
    .image-gallery-image {
        /* height: 80vh !important; */
    }
}

@media only screen and (max-width: 480px) {
    .image-gallery-image {
        /* width: 100vw !important; */
        width: 100% !important;
    }

    .room-gallery {
        width: 90%;
    }

    .image-gallery {
        width: 90% !important;
    }

}

@media only screen and (max-width: 992px) {
    .image-gallery {
        width: 100% !important;
    }

    .image-gallery-image {
        /* width: 100vw !important; */
        width: 100% !important;
    }

    .room-gallery {
        width: 100% !important;
    }
}
.room-one {
    display: flex;
    align-items: center;
    height: 70vh;
    width: 90%;
    background-color: white;
    color: black;
    border: 2px solid white;
    margin-top: 40px;
    margin-bottom: 40px;
}

.room-one-right {
    flex: 0.5 1;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-one-left h1 {
    margin-bottom: 20px;
}

.room-one-left {
    flex: 0.5 1;
    flex-direction: column;
    padding-left: 40px;

}

.room-one-left > p {
    font-size: 120%;
}

.room-one-image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-one-image-container image {
    height: 30vh;
    width: auto;
}

.room-one-image {
    height: 50vh;
}

.icon-group {
    display: flex;
    align-items: center;
    font-size: 120%;
}

.icon-group p {
    margin-left: 30px;
}

@media only screen and (max-width: 991px) {
    .room-one {
        height: 90vh !important;
        flex-direction: column-reverse !important;
        height: -webkit-max-content !important;
        height: max-content !important;
    }

    .room-one-image-container {
        width: 90vw;
    }
}

@media only screen and (max-width: 768px) {
    .room-one {
        flex-direction: column-reverse !important;
        height: -webkit-max-content !important;
        height: max-content !important;
        padding: 20px;
    }

    .room-one-left p {
        font-size: 100%;
        margin-top: 10px;
    }

    .room-one-left {
        padding-left: 0px;
        padding: 20px;
    }

    .room-one-left h1 {
        margin-top: 10px;
    }
}
.contact {
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /* width: 100vw; */
    /* background-color: #D1C0A870; */
    background-color: white;
    background-color: #D1C0A81A;
}

.hr-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact hr {
    width: 80%;
}

.contact-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    border-top: 4px solid white;
    border-bottom: 4px solid white;

}

.contact-header h1 {
    color: white;
    /* font-family: 'Allison', cursive; */
    font-size: 50px;
    letter-spacing: 5px;

}

.contact-blurb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-blurb p {
    text-align: center;
    width: 75%;
    font-size: 20px;
    font-weight: 200;
}


.contact-container { 
    display: flex;
    align-items: center;
    /* padding: 20px; */
    min-height: 75vh;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* border-bottom: 4px solid white; */
    margin-bottom: 100px;
}

.contact-left {
    flex: 0.4 1;
    /* border: 2px solid white; */
    height: 550px;
    min-height: 450px;
    /* border-radius: 5px; */
    width: 600px;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    /* border-right: 2px solid white; */
}

.contact-left p {
    color: black;
    font-weight: 300;
}

.interest-row {
    /* padding: 5%; */
    width: 80%;
    display: flex;
}

.interest-row p {
    font-size: 20px;
}

.contact-left input {
    padding: 1%;
    outline-width: 0;
    width: 80%;
    font-size: 18px;

}

.contact-left h2 {
    color: black;
    font-size: 35px;
}

.contact-left p {
    font-size: 25px;
}

.contact-left textarea {
    outline-width: 0;
    width: 80%;
    padding: 1%;
    font-size: 18px;

}

.contact-right {
    flex: 0.4 1;
    display: flex;
    align-items: center;
    height: 450px;
    min-height: 450px;
    width: 600px;
    box-shadow: 5px 2px 10px grey;

}

.checkbox-row {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    width: 80%;
}

.contact-button-row {
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contact-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 30px 5px 30px;
    border: 2px solid #D1C0A8;
    background-color: #D1C0A8;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 20px;
    color: white;
    font-weight: 600;
    box-shadow: 3px 4px 2px lightgrey;
    outline-width: 0;
}

.contact-button-row button:hover {
    cursor: pointer;
    background-color: white;
    color: #D1C0A8;
}

.contact-right iframe {
    border: none !important;
    /* border: 1px solid #D1C0A870; */
    /* border-radius: 5px; */
    height: 100%;
    width: 100%;

}

.MuiCheckbox-root {
    color: black !important;
}

.MuiTypography-body1 {
    color: black;
    font-family: 'Gothic A1', sans-serif !important;
    font-size: 20px !important;
}

.error-message {
    color: red !important;
    font-size: 25px;
    width: 80%;
    text-align: center;
    transition: ease-in 3ms;
}

.contact-directions {
    background-color: #D1C0A870;
    /* display: flex;
    flex-direction: column; */
}

.contact-directions-header {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.contact-directions-container {
    display: flex;
    /* align-items: center; */
    color: white;
    height: 100% !important;
    border-bottom: 4px solid white;
}

.contact-directions-left {
    flex: 0.6 1;
    border-right: 2px solid white;
    flex-direction: column;
    padding: 20px;
}

.contact-directions-left p {
    font-size: 18px;
}

.contact-directions h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.contact-directions-item {
    margin-bottom: 20px;
}

.contact-directions-right {
    flex: 0.4 1;
    flex-direction: column;
    height: 100%;
    padding: 20px;

}

.contact-details-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 40px;
}

.contact-details-container p {
    font-size: 20px;
}

.contact-directions-right p {
    font-size: 22px;
    margin-bottom: 10px;
    transition: ease-in-out 0.5s !important;
}

.contact-directions-right p:hover {
    cursor: pointer;
    opacity: 0.7;
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
}

.contact-directions-header h2 {
    color: white;
    font-size: 40px;
}

.contact-dir-boxes {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #D1C0A870;
}

.dir-boxes-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (max-width: 1024px) {


    .contact-container {
        flex-direction: column;
    }

    .contact-left {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 991px) {

    .contact-left {
        height: 80vh !important;
        flex: none;
        width: 700px;
    }

    .image-tabs {
        width: 70% !important;
    }

    .image-tabs p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .contact-left {
        width: 100%;
        align-items: flex-start;
    }

    .contact-right iframe {
        width: 100vw !important;
    }
    
    .dir-boxes-row {
        flex-direction: column;
    }
    
    .contact-dir-boxes {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contact-left input {
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 768px) {
    .contact-left {
        width: 100%;
        align-items: center;
    }

    .contact-right iframe {
        width: 100vw !important;
    }

    .contact-right {
        width: auto;
    }

    
    .dir-boxes-row {
        flex-direction: column;
    }
    
    .contact-dir-boxes {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contact-blurb p {
        width: 75%;
    }

    .contact-details-container p {
        width: 75%;
        text-align: center;
    }
}

@media only screen and (max-width: 992px) {
    .dir-boxes-row {
        flex-direction: column;
    }

}
.directions-box {
    display: flex;
    height: 50vh;
    width: 40%;
    box-shadow: 5px 5px 5px grey;
    background-color: white;
    margin: 25px 0 25px 0;
    transition: 200ms ease-in;
}

.directions-box:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}



.directions-box-left {
    display: flex;
    flex-direction: column;
    flex: 0.7 1;
    justify-content: center;
    padding: 20px;
}

.directions-box-left h3 {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
}

.directions-box-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.3 1;
}

.directions-box-right button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 30px 5px 30px;
    border: 2px solid #D1C0A8;
    background-color: #D1C0A8;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 20px;
    color: white;
    font-weight: 600;
    box-shadow: 3px 4px 2px lightgrey;

}

.directions-box-right button:hover {
    cursor: pointer;
    background-color: white;
    color: #D1C0A8;
}

@media only screen and (max-width: 992px) {
    .directions-box {
        width: 80%;
        flex-direction: column;
        height: -webkit-max-content;
        height: max-content;
    }

    .directions-box-right {
        margin-bottom: 50px;
    }

    .directions-box-left {
        text-align: center;
    }

}

@media only screen and (max-width: 768px) {

}
.gallery {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #D1C0A8;
    /* padding-bottom: 40px; */
}

.image-grid-container {
    /* border-top: 2px solid white !important; */
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid white;
    overflow: hidden;
    justify-content: space-between;
}

.gallery-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    width: 100%;
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery h1 {
    color: white;
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    /* margin-bottom: 10px; */
} 

.grid-row {
    height: 30vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    

}

.height-30 {
    height: 30vh;
}

.height-40 {
    height: 40vh;
}

.height-50 {
    height: 50vh;
}

.grid-item {
    border: 1px solid white;
    /* height: 30vh !important; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item:hover {
    opacity: 0.8;
    cursor: pointer;
}

.grid-item:active {
    opacity: 0.5;
}

.mr-5 {
    margin-right: 5px;
}

.ml-5 {
    margin-left: 5px;
}
.mt-5 {
    margin-top: 5px;
}

.grid-item img {
    object-fit: cover !important;
    height: 30vh;
    width: 100%;
    /* box-shadow: 1px 1px 1px grey; */

}

.height-30-img {
    height: 30vh !important;
}

.height-40 img {
    height: 40vh !important;
}

.height-50 img {
    height: 50vh !important;
}

.flex-10 {
    flex: 0.1 1;
}

.flex-20 {
    flex: 0.2 1;
}

.flex-30 {
    flex: 0.3 1
}

.flex-40 {
    flex: 0.4 1;
}

.flex-50 {
    flex: 0.5 1;
}

.image-tabs {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    margin-bottom: 40px;
    margin-top: 40px;

}

.image-tabs p {
    font-size: 22px;
    font-weight: 300;
    color: white;
    text-align: center;
}

.image-tabs p:active {
    opacity: 0.5;
}

.active-image-tab {
    opacity: 0.7;
}

.active-image-tab:hover {
    cursor: default !important;
}

.image-tabs p:hover {
    cursor: pointer;
    opacity: 0.7;
}

@media only screen and (max-width: 991px) {

    .image-tabs {
        width: 80% !important;
    }

    .image-tabs p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 480px) {
.image-tabs p {
    font-size: 15px !important;
}
}

@media only screen and (max-width: 768px) {
    .room-one {
        flex-direction: column-reverse !important;
        height: -webkit-max-content !important;
        height: max-content !important;
        padding: 20px;
    }

    .image-tabs p {
        font-size: 18px;
    }

    .image-tabs {
        width: 90% !important;
    }

    #general-tab {
        margin-right: 10px;
    }

    #facilities-tab {
        margin-left: 10px;
    }
}
/* The Modal (background) */
.image-modal {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
    transition: ease-in 3ms;
  }
  
  /* Modal Content/Box */
  .image-modal-content {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* margin: 15% auto; */
    /* padding: 20px; */
    /* border: 1px solid #888; */
    /* width: 40%;  */
    height: 100vh;
    width: 100%;
  }

  .image-modal-content img {
      /* opacity: 0.1; */
      width: 95vw !important;
  }
.about {
    display: flex;
    flex-direction: column;
    background-color: #D1C0A8;
}

.about-boxes-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.about-box-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.about-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    /* border-top: 4px solid white; */
    /* border-bottom: 4px solid white; */
    width: 100%;
}

.about-header h1 {
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    /* margin-bottom: 40px; */
    color: white
}

.about-one {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #D1C0A8;
    margin-top: 0px;
    /* border-top: 4px solid white; */
}

.about-one h2 {
    font-size: 40px !important;
}

.about-one-left {
    flex: 0.5 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 2px solid white; */
}

.about-one-left img {
    width: 70%;
    opacity: 0.9;
    box-shadow: 5px 5px 5px grey;

    /* height: 70vh; */
    object-fit: cover;
    margin-top: 100px;
    margin-bottom: 100px;
}

.about-one-right {
    flex: 0.5 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    color: white;
    height: 100%;
}

.about-one-right p {
    padding: 40px;
    font-size: 20px;
}

.about-image-container {
    flex: 0.5 1;
    align-items: center;
    justify-content: center;
}

.about-image-container img {
    object-fit: contain;
    width: 100%;
    height: 60vh !important;
}

.about-text-container {
    flex: 0.6 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    height: 90%;
}

.about-two {
    height: -webkit-max-content;
    height: max-content;
    background-color: white;
    display: flex;
    align-items: center;
    /* border-top: 4px solid white; */
}

.about-two-left {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 0.4 1;
    /* border-right: 2px solid white; */
    color: white;
    text-align: center;
    padding: 20px;
}

.about-two-left h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 40px;
    color: black;
}

.about-two-left p {
    font-size: 18px;
    color: black;
    margin-bottom: 50px;
}

.about-two-right {
    flex: 0.6 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-two-right img {
    width: 80%;
    box-shadow: 5px 5px 5px grey;
    /* height: 70vh; */
    margin-top: 100px;
    margin-bottom: 100px;
}

.about-text-container h2 {
    text-align: center;
    font-size: 35px;
    /* margin-bottom: 20px; */
}

.about-text-container p {
    text-align: center;
}

.about-three {
    display: flex;
    align-items: center;
    height: 70vh;
    background-color: grey;
    /* border-bottom: 4px solid white; */
    /* border-top: 4px solid white; */
    color: white;
}

.about-three-left {
    padding-left: 40px;

    flex: 0.6 1;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    height: 70vh;
    /* border-right: 2px solid white; */
}

.about-three-left h2 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
}

.about-three-left p {
    font-size: 20px;
}

.about-three-right {
    padding-left: 40px;
    flex: 0.4 1;
    display: flex;
    flex-direction: column;
    height: 70vh;
    /* align-items: center; */
    justify-content: space-evenly;
}

.about-three-right p {
    font-size: 20px;
}

.about-three-right h2 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
}

@media only screen and (max-width: 480px) {
    .about-box-row {
        flex-direction: column !important;
    }
}

@media only screen and (max-width: 992px) {

    .interest-row {
        justify-content: center;
    }

    .about-boxes-container {
        flex-direction: column !important;
    }
    

    .about-one {
        flex-direction: column;
        height: -webkit-max-content;
        height: max-content;
    }

    .about-one-left img {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .about-two {
        flex-direction: column-reverse;
        height: -webkit-max-content;
        height: max-content;
    }

    .about-two h2 {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .about-two-right img {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}

.nearby-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-max-content;
    height: max-content;
    /* width: 30vw; */
    background-color: white;
    margin-bottom: 40px;
    box-shadow: 5px 5px 5px grey;
    transition: 200ms ease-in;
    flex: 0.4 1;
}

.nearby-box:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
} 

.nearby-box-image-container {
    /* min-height: 60%; */
    /* height: 60%; */
    flex: 0.6 1;
    overflow: hidden;
    width: 100%;
}

.nearby-box-image-container img {
    height: 180px;
    width: 100%;
    transition: 200ms ease-in;
    object-fit: cover;
}

.nearby-box-image-container img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.nearby-box-content {
    flex: 0.4 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nearby-box h3 {
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
}

.nearby-box p {
    text-align: center;
}

.nearby-box-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.nearby-box-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 25px 10px 25px;
    border: 1px solid #D1C0A870;
    background-color: transparent;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 14px;
}

.nearby-box-button-row button:hover {
    cursor: pointer;
    background-color: #D1C0A870;
    color: black;
}

@media screen and (max-width: 480px) {

    .nearby-box {
        width: 80vw;
        flex: 1 1;
    }

}

@media screen and (max-width: 768px) {
    .nearby-box {
        /* width: 70vw !important; */
        /* flex: 0.5 !important; */
        /* height: 50vh; */

    }

    .nearby-box h3 {
        font-size: 85%;
    }

    .nearby-box p {
        font-size: 95%;
    }

    .nearby-box-image-container img {
    }
}

