.room-one {
    display: flex;
    align-items: center;
    height: 70vh;
    width: 90%;
    background-color: white;
    color: black;
    border: 2px solid white;
    margin-top: 40px;
    margin-bottom: 40px;
}

.room-one-right {
    flex: 0.5;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-one-left h1 {
    margin-bottom: 20px;
}

.room-one-left {
    flex: 0.5;
    flex-direction: column;
    padding-left: 40px;

}

.room-one-left > p {
    font-size: 120%;
}

.room-one-image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-one-image-container image {
    height: 30vh;
    width: auto;
}

.room-one-image {
    height: 50vh;
}

.icon-group {
    display: flex;
    align-items: center;
    font-size: 120%;
}

.icon-group p {
    margin-left: 30px;
}

@media only screen and (max-width: 991px) {
    .room-one {
        height: 90vh !important;
        flex-direction: column-reverse !important;
        height: max-content !important;
    }

    .room-one-image-container {
        width: 90vw;
    }
}

@media only screen and (max-width: 768px) {
    .room-one {
        flex-direction: column-reverse !important;
        height: max-content !important;
        padding: 20px;
    }

    .room-one-left p {
        font-size: 100%;
        margin-top: 10px;
    }

    .room-one-left {
        padding-left: 0px;
        padding: 20px;
    }

    .room-one-left h1 {
        margin-top: 10px;
    }
}