
.header {
    /* background-color: #040403; */
    height: 100px;
    color: black;
    background-color: white;
    display: flex;
    align-items: center;
    z-index: 800;
    position: sticky !important;
    /* width: 100vw; */
    top: 0;
    width: 100vw;
}
 
.header h1 {
    font-size: 40px;
    font-family: 'Allison', cursive;
    /* background-color: black; */
    color: black;
    outline-width: 0;
}

.nav-header {
    text-decoration: none;

}

.header-left img {
    transition: 200ms ease-in;
}

.header-left img:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.header-navlink {
    font-size: 20px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    transition: ease-in-out 0.4s;
    /* font-family: 'Della Respira', serif;} */
    font-family: 'Gothic A1', sans-serif;
    /* font-weight: 200; */
    
}

.header-navlink:hover {
    transform: scale(1.08);
    /* color: #ffffff; */
    color: #D1C0A8;

}



.header-navlink-active {
    color: #D1C0A8;
}

.header-navlink-active:hover {
    transform: none;
    /* color: white; */
    cursor: default;
}


.header-left {
    flex: 0.4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-middle {
    flex: 0.5;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-right {
    flex: 0.1;
    align-items: center;
}

.MuiSvgIcon-root:hover {
    cursor: pointer;
}

/* .MuiSvgIcon-root {
    display: none;
} */

.header-right {
    display: none;
}

@media only screen and (max-width: 481px) {
    .header-left img {
        max-width: 90vw;
    }

    .header-left {
        /* display: none; */
        max-width: 90vw;

    }

}

@media only screen and (max-width: 991px) {
    /* .MuiSvgIcon-root {
        display: block;
    } */

    .header-left {
        flex: 0.9 !important;
    }

    .header-right {
        flex: 0.1 !important;
    }

    .header-right {
        display: block;
        justify-content: flex-end !important;
    }

    .header-middle {
        display: none;
    }
}


@media only screen and (max-width: 991px) {
    .header h1 {
        font-size: 30px;
    }

    .header-navlink {
        font-size: 18px;
    }

    
}