.room-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.room-gallery {
    width: 40vw;
    /* height: 50vh !important  ; */
}



.room-gallery .image-gallery-image {
    height: 50vh !important;
    width: 40vw;
    object-fit: fill;
}

.fullscreen .image-gallery-image {
    height: 100vh !important;
    width: 100%;
    object-fit: contain !important;

}

.fullscreen {
    height: 100vh !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid black !important;
}

.image-gallery-thumbnail:hover {
    border: 4px solid black !important;
}


@media only screen and (max-width: 991px) {
    .image-gallery-image {
        /* height: 80vh !important; */
    }
}

@media only screen and (max-width: 480px) {
    .image-gallery-image {
        /* width: 100vw !important; */
        width: 100% !important;
    }

    .room-gallery {
        width: 90%;
    }

    .image-gallery {
        width: 90% !important;
    }

}

@media only screen and (max-width: 992px) {
    .image-gallery {
        width: 100% !important;
    }

    .image-gallery-image {
        /* width: 100vw !important; */
        width: 100% !important;
    }

    .room-gallery {
        width: 100% !important;
    }
}