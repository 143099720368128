.room-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 30vw;
    background-color: white;
    margin-bottom: 100px;
    box-shadow: 5px 5px 5px grey;
    transition: 200ms ease-in;
}

.room-box:hover {
    transform: scale(1.05);
}

.room-box-image-container {
    flex: 0.6;
    overflow: hidden;
    width: 100%;
}

.room-box-image-container img {
    height: auto;
    width: 100%;
    transition: 200ms ease-in;
}

.room-box-image-container img:hover {
    transform: scale(1.05);
}

.room-box-content {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.room-box h3 {
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
}

.room-box-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-box-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 25px 10px 25px;
    border: 1px solid #D1C0A870;
    background-color: transparent;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 14px;
    margin-bottom: 20px;
}

.room-box-button-row button:hover {
    cursor: pointer;
    background-color: #D1C0A870;
    color: black;
}

@media screen and (max-width: 480px) {

    .room-box {
        width: 80vw !important;
    }


}

@media screen and (max-width: 768px) {
    .room-box {
        width: 70vw !important;
    }
    .room-box-button-row button {
        margin-bottom: 20px;
    }

}
