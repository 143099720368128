.gallery {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #D1C0A8;
    /* padding-bottom: 40px; */
}

.image-grid-container {
    /* border-top: 2px solid white !important; */
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid white;
    overflow: hidden;
    justify-content: space-between;
}

.gallery-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    width: 100%;
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery h1 {
    color: white;
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    /* margin-bottom: 10px; */
} 

.grid-row {
    height: 30vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    

}

.height-30 {
    height: 30vh;
}

.height-40 {
    height: 40vh;
}

.height-50 {
    height: 50vh;
}

.grid-item {
    border: 1px solid white;
    /* height: 30vh !important; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item:hover {
    opacity: 0.8;
    cursor: pointer;
}

.grid-item:active {
    opacity: 0.5;
}

.mr-5 {
    margin-right: 5px;
}

.ml-5 {
    margin-left: 5px;
}
.mt-5 {
    margin-top: 5px;
}

.grid-item img {
    object-fit: cover !important;
    height: 30vh;
    width: 100%;
    /* box-shadow: 1px 1px 1px grey; */

}

.height-30-img {
    height: 30vh !important;
}

.height-40 img {
    height: 40vh !important;
}

.height-50 img {
    height: 50vh !important;
}

.flex-10 {
    flex: 0.1;
}

.flex-20 {
    flex: 0.2;
}

.flex-30 {
    flex: 0.3
}

.flex-40 {
    flex: 0.4;
}

.flex-50 {
    flex: 0.5;
}

.image-tabs {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    margin-bottom: 40px;
    margin-top: 40px;

}

.image-tabs p {
    font-size: 22px;
    font-weight: 300;
    color: white;
    text-align: center;
}

.image-tabs p:active {
    opacity: 0.5;
}

.active-image-tab {
    opacity: 0.7;
}

.active-image-tab:hover {
    cursor: default !important;
}

.image-tabs p:hover {
    cursor: pointer;
    opacity: 0.7;
}

@media only screen and (max-width: 991px) {

    .image-tabs {
        width: 80% !important;
    }

    .image-tabs p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 480px) {
.image-tabs p {
    font-size: 15px !important;
}
}

@media only screen and (max-width: 768px) {
    .room-one {
        flex-direction: column-reverse !important;
        height: max-content !important;
        padding: 20px;
    }

    .image-tabs p {
        font-size: 18px;
    }

    .image-tabs {
        width: 90% !important;
    }

    #general-tab {
        margin-right: 10px;
    }

    #facilities-tab {
        margin-left: 10px;
    }
}