.rooms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
    background-color: #D1C0A870;
    padding-bottom: 100px;
    padding-top: 100px;
    max-width: 100vw;
}

.rooms p {
    font-size: 100% !important;
}

.rooms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
    /* padding-bottom: 100px; */
}

.rooms-header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    width: 100%;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    margin-bottom: 40px;
}

.rooms-header-row > h1 {
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    color: white;
    /* margin-bottom: 40px; */
}