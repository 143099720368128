.footer {
    background-color: white;
    height: 20vh;
    color: black;
    display: flex;
    /* position: fixed; */
    bottom: 0;
    z-index: 800;
    width: 100%;
}

.footer-left {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.footer h1 {
    font-family: 'Allison', cursive;
}

.footer-middle {
    flex: 0.4;
}

.footer-right {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-right > h2 {
    margin-bottom: 7px;
    font-size: 24px;
}

.footer-right p {
    margin-bottom: 4px;
    font-size: 17px;
}

@media only screen and (max-width: 480px) {
    .footer {
        flex-direction: column;
        height: max-content;
        /* padding: 10px; */
    }

    .footer-middle {
        display: none;
    }

    .footer-right {
        display: none;
    }

    .footer-left img {
        width: 100% ;
    }
}

@media only screen and (max-width: 768px) {
    .footer-left {
        flex: 1;
        max-width: 100%;
    }
    .footer-right {
        display: none;
    }
    .footer-middle {
        display: none;
    }
}