.about {
    display: flex;
    flex-direction: column;
    background-color: #D1C0A8;
}

.about-boxes-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.about-box-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.about-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    /* border-top: 4px solid white; */
    /* border-bottom: 4px solid white; */
    width: 100%;
}

.about-header h1 {
    /* margin-top: 40px; */
    font-size: 50px;
    font-family: 'Allison', cursive;
    letter-spacing: 5px;
    /* margin-bottom: 40px; */
    color: white
}

.about-one {
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #D1C0A8;
    margin-top: 0px;
    /* border-top: 4px solid white; */
}

.about-one h2 {
    font-size: 40px !important;
}

.about-one-left {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 2px solid white; */
}

.about-one-left img {
    width: 70%;
    opacity: 0.9;
    box-shadow: 5px 5px 5px grey;

    /* height: 70vh; */
    object-fit: cover;
    margin-top: 100px;
    margin-bottom: 100px;
}

.about-one-right {
    flex: 0.5;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    color: white;
    height: 100%;
}

.about-one-right p {
    padding: 40px;
    font-size: 20px;
}

.about-image-container {
    flex: 0.5;
    align-items: center;
    justify-content: center;
}

.about-image-container img {
    object-fit: contain;
    width: 100%;
    height: 60vh !important;
}

.about-text-container {
    flex: 0.6;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    height: 90%;
}

.about-two {
    height: max-content;
    background-color: white;
    display: flex;
    align-items: center;
    /* border-top: 4px solid white; */
}

.about-two-left {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 0.4;
    /* border-right: 2px solid white; */
    color: white;
    text-align: center;
    padding: 20px;
}

.about-two-left h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 40px;
    color: black;
}

.about-two-left p {
    font-size: 18px;
    color: black;
    margin-bottom: 50px;
}

.about-two-right {
    flex: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-two-right img {
    width: 80%;
    box-shadow: 5px 5px 5px grey;
    /* height: 70vh; */
    margin-top: 100px;
    margin-bottom: 100px;
}

.about-text-container h2 {
    text-align: center;
    font-size: 35px;
    /* margin-bottom: 20px; */
}

.about-text-container p {
    text-align: center;
}

.about-three {
    display: flex;
    align-items: center;
    height: 70vh;
    background-color: grey;
    /* border-bottom: 4px solid white; */
    /* border-top: 4px solid white; */
    color: white;
}

.about-three-left {
    padding-left: 40px;

    flex: 0.6;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    height: 70vh;
    /* border-right: 2px solid white; */
}

.about-three-left h2 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
}

.about-three-left p {
    font-size: 20px;
}

.about-three-right {
    padding-left: 40px;
    flex: 0.4;
    display: flex;
    flex-direction: column;
    height: 70vh;
    /* align-items: center; */
    justify-content: space-evenly;
}

.about-three-right p {
    font-size: 20px;
}

.about-three-right h2 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
}

@media only screen and (max-width: 480px) {
    .about-box-row {
        flex-direction: column !important;
    }
}

@media only screen and (max-width: 992px) {

    .interest-row {
        justify-content: center;
    }

    .about-boxes-container {
        flex-direction: column !important;
    }
    

    .about-one {
        flex-direction: column;
        height: max-content;
    }

    .about-one-left img {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .about-two {
        flex-direction: column-reverse;
        height: max-content;
    }

    .about-two h2 {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .about-two-right img {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}
