@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Della+Respira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

* {
  margin: 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  outline-width: 0;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 600ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 6000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.header-img {
  height: 40vh;
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .header-img {
    height: 25vh;
  }
}