.dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    background-color: white;
    padding-right: 20px;
    /* width: max-content; */
    padding-bottom: 20px;
    z-index: 700;
    right: 0 !important;
    position: fixed;
    width: 100%;
    height: 0px;
    transition: height 1s;
}

.dropdown-navlink {
    margin-bottom: 10px !important;
}
