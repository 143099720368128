.directions-box {
    display: flex;
    height: 50vh;
    width: 40%;
    box-shadow: 5px 5px 5px grey;
    background-color: white;
    margin: 25px 0 25px 0;
    transition: 200ms ease-in;
}

.directions-box:hover {
    transform: scale(1.04);
}



.directions-box-left {
    display: flex;
    flex-direction: column;
    flex: 0.7;
    justify-content: center;
    padding: 20px;
}

.directions-box-left h3 {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
}

.directions-box-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.3;
}

.directions-box-right button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 30px 5px 30px;
    border: 2px solid #D1C0A8;
    background-color: #D1C0A8;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 20px;
    color: white;
    font-weight: 600;
    box-shadow: 3px 4px 2px lightgrey;

}

.directions-box-right button:hover {
    cursor: pointer;
    background-color: white;
    color: #D1C0A8;
}

@media only screen and (max-width: 992px) {
    .directions-box {
        width: 80%;
        flex-direction: column;
        height: max-content;
    }

    .directions-box-right {
        margin-bottom: 50px;
    }

    .directions-box-left {
        text-align: center;
    }

}

@media only screen and (max-width: 768px) {

}