.contact {
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /* width: 100vw; */
    /* background-color: #D1C0A870; */
    background-color: white;
    background-color: #D1C0A81A;
}

.hr-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact hr {
    width: 80%;
}

.contact-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    border-top: 4px solid white;
    border-bottom: 4px solid white;

}

.contact-header h1 {
    color: white;
    /* font-family: 'Allison', cursive; */
    font-size: 50px;
    letter-spacing: 5px;

}

.contact-blurb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-blurb p {
    text-align: center;
    width: 75%;
    font-size: 20px;
    font-weight: 200;
}


.contact-container { 
    display: flex;
    align-items: center;
    /* padding: 20px; */
    min-height: 75vh;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* border-bottom: 4px solid white; */
    margin-bottom: 100px;
}

.contact-left {
    flex: 0.4;
    /* border: 2px solid white; */
    height: 550px;
    min-height: 450px;
    /* border-radius: 5px; */
    width: 600px;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    /* border-right: 2px solid white; */
}

.contact-left p {
    color: black;
    font-weight: 300;
}

.interest-row {
    /* padding: 5%; */
    width: 80%;
    display: flex;
}

.interest-row p {
    font-size: 20px;
}

.contact-left input {
    padding: 1%;
    outline-width: 0;
    width: 80%;
    font-size: 18px;

}

.contact-left h2 {
    color: black;
    font-size: 35px;
}

.contact-left p {
    font-size: 25px;
}

.contact-left textarea {
    outline-width: 0;
    width: 80%;
    padding: 1%;
    font-size: 18px;

}

.contact-right {
    flex: 0.4;
    display: flex;
    align-items: center;
    height: 450px;
    min-height: 450px;
    width: 600px;
    box-shadow: 5px 2px 10px grey;

}

.checkbox-row {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    width: 80%;
}

.contact-button-row {
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contact-button-row button {
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 1px;
    padding: 10px 30px 5px 30px;
    border: 2px solid #D1C0A8;
    background-color: #D1C0A8;
    /* font-size: 15px; */
    transition: 200ms ease-in;
    font-size: 20px;
    color: white;
    font-weight: 600;
    box-shadow: 3px 4px 2px lightgrey;
    outline-width: 0;
}

.contact-button-row button:hover {
    cursor: pointer;
    background-color: white;
    color: #D1C0A8;
}

.contact-right iframe {
    border: none !important;
    /* border: 1px solid #D1C0A870; */
    /* border-radius: 5px; */
    height: 100%;
    width: 100%;

}

.MuiCheckbox-root {
    color: black !important;
}

.MuiTypography-body1 {
    color: black;
    font-family: 'Gothic A1', sans-serif !important;
    font-size: 20px !important;
}

.error-message {
    color: red !important;
    font-size: 25px;
    width: 80%;
    text-align: center;
    transition: ease-in 3ms;
}

.contact-directions {
    background-color: #D1C0A870;
    /* display: flex;
    flex-direction: column; */
}

.contact-directions-header {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.contact-directions-container {
    display: flex;
    /* align-items: center; */
    color: white;
    height: 100% !important;
    border-bottom: 4px solid white;
}

.contact-directions-left {
    flex: 0.6;
    border-right: 2px solid white;
    flex-direction: column;
    padding: 20px;
}

.contact-directions-left p {
    font-size: 18px;
}

.contact-directions h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.contact-directions-item {
    margin-bottom: 20px;
}

.contact-directions-right {
    flex: 0.4;
    flex-direction: column;
    height: 100%;
    padding: 20px;

}

.contact-details-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 40px;
}

.contact-details-container p {
    font-size: 20px;
}

.contact-directions-right p {
    font-size: 22px;
    margin-bottom: 10px;
    transition: ease-in-out 0.5s !important;
}

.contact-directions-right p:hover {
    cursor: pointer;
    opacity: 0.7;
    transform: scale(1.07);
}

.contact-directions-header h2 {
    color: white;
    font-size: 40px;
}

.contact-dir-boxes {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #D1C0A870;
}

.dir-boxes-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (max-width: 1024px) {


    .contact-container {
        flex-direction: column;
    }

    .contact-left {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 991px) {

    .contact-left {
        height: 80vh !important;
        flex: none;
        width: 700px;
    }

    .image-tabs {
        width: 70% !important;
    }

    .image-tabs p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .contact-left {
        width: 100%;
        align-items: flex-start;
    }

    .contact-right iframe {
        width: 100vw !important;
    }
    
    .dir-boxes-row {
        flex-direction: column;
    }
    
    .contact-dir-boxes {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contact-left input {
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 768px) {
    .contact-left {
        width: 100%;
        align-items: center;
    }

    .contact-right iframe {
        width: 100vw !important;
    }

    .contact-right {
        width: auto;
    }

    
    .dir-boxes-row {
        flex-direction: column;
    }
    
    .contact-dir-boxes {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contact-blurb p {
        width: 75%;
    }

    .contact-details-container p {
        width: 75%;
        text-align: center;
    }
}

@media only screen and (max-width: 992px) {
    .dir-boxes-row {
        flex-direction: column;
    }

}