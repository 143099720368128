.main-room {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    width: 90%;
    color: black;
    background-color: white;
    /* margin-top: 100px; */
    border: 2px solid white;
}

.main-room-left {
    flex: 0.5;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-room-right {
    flex: 0.5;
    flex-direction: column;

}

.main-room-right h1 {
    margin-bottom: 20px;
}

.main-room-right > p {
    font-size: 120%;
}

.main-room-image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100vw; */
}

.main-room-image-container image {
    height: 30vh;
    width: auto;
}

.main-room-image {
    height: 50vh;
}

.icon-group {
    display: flex;
    align-items: center;
    font-size: 120%;
    margin-bottom: 5px;
}

.icon-group p {
    margin-left: 30px;
}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 991px) {
    .main-room {
        height: 80vh !important;
    }

}

@media only screen and (max-width: 992px) {
    .main-room {
        flex-direction: column !important;
        height: max-content !important;
        padding: 20px;
        width: 90vw;
    }

    .main-room-image-container {
        width: 90vw;
    }

    .main-room-right {
        padding: 20px;
    }

    .main-room-right p {
        font-size: 100%;
        margin-top: 10px;
    }

    .main-room-right h1 {
        margin-top: 10px;
    }

}